import { Component, OnInit, ViewChild } from '@angular/core';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';
import { Location } from '@angular/common';

@Component({
  selector: 'page-rsc-cookies',
  templateUrl: 'rsc-cookies.html'
})

export class RscCookiesPage implements OnInit {

  // Component variables.
  displayBack = true;
  @ViewChild('Content', { static: true }) content;

  constructor(private location: Location) { }

  ngOnInit(): void {
    // check if logged in
    let canAccess = MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser => {
      let isLoggedIn = (appUser && appUser.api_token);
      if (isLoggedIn) {
        this.displayBack = false;
      }
    });

    const popupExists = document.getElementById("onetrust-pc-sdk");
    const oneTrust2Exists = document.querySelector("[data-domain-script='e8a773f1-a4eb-4312-b1b9-72426df64821-test']");

    if (popupExists) {
      popupExists.remove();
    }

    if (oneTrust2Exists) {
      oneTrust2Exists.remove();
      addOneTrust2();
    } else {
      addOneTrust2();
    }

    function addOneTrust2() {
      var oneTrust2 = document.createElement('script')
      oneTrust2.setAttribute("type", "text/javascript")
      oneTrust2.setAttribute("src", "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js")
      oneTrust2.setAttribute("data-domain-script", "e8a773f1-a4eb-4312-b1b9-72426df64821-test")
      oneTrust2.text = 'function OptanonWrapper() { }';
      document.getElementsByTagName("head")[0].appendChild(oneTrust2)
    }
  }

  /** 
  * Back.
  */
  back() {
    this.location.back();
  }
}